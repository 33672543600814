import 'the-new-css-reset/css/reset.css'
import { PageLayout } from './components/layouts/PageLayout'
import { Sidebar } from './form/components/Sidebar'
import { MedipilotForm } from './form/MedipilotForm'
import { useFormContext } from './form/context/FormContextProvider'
import ReactGA from 'react-ga4';

export const App = () => {
  const { resetForm } = useFormContext()
  ReactGA.initialize('G-4JN1YM2D55');
  // Send pageview with a custom path
  ReactGA.send({ hitType: "pageview", page: "/app", title: "Landing Page" });
  return <PageLayout resetForm={resetForm} sidebar={<Sidebar />}>
    <MedipilotForm />
  </PageLayout>
}
