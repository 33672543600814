export const getErrorMessage = (errors, fieldName) => {
  const fieldParts = fieldName.split('.');
  let temp = errors;
  for (const part of fieldParts) {
    temp = temp[part];
    if (!temp) {
      // Break the loop if any part is not found
      break;
    }
  }
  return temp as string
}

export const getFieldTouched = (touched, fieldName) => {
  const fieldParts = fieldName.split('.');
  let temp = touched;
  for (const part of fieldParts) {
    temp = temp[part];
    if (!temp) {
      // Break the loop if any part is not found
      break;
    }
  }
  return temp as boolean
}
