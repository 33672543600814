import { useConfig } from "@/config"
import { useLanguage } from "@/translations/hooks/useLanguage"
import axios from "axios"

export const useAxios = () => {
  const config = useConfig()
  const { language } = useLanguage()

  const axiosInstance = axios.create({
    baseURL: config.api,
  })

  axiosInstance.interceptors.request.use((request) => {
    request.headers['ApiKey'] = config.apiKey
    request.headers['Accept-Language'] = language
    return request
  })

  return axiosInstance
}