import { v4 as uuidv4 } from 'uuid'
import { Button } from '@/components/button/Button'
import { MedicationForm } from '@/components/forms/MedicationForm'
import { StepLayout } from '@/components/layouts/StepLayout'
import { ErrorMessage, useFormikContext } from 'formik'
import { useState } from 'react'
import { General } from 'untitledui-js'
import { MedicationSchedule } from '../form.types'
import { useTranslation } from 'react-i18next'
import { MedicationCard } from '@/components/medication-card/MedicationCard'
import {
  IntervalType,
  MedicationFormData,
} from '@/components/forms/medication.types'
import { LocalStorageKeys } from '@/constants/LocalStorageKeys'
import ReactGA from 'react-ga4'

type Props = {
  onNext?: () => void
}

export const MedicationStep = ({ onNext }: Props) => {
  const { t } = useTranslation()
  const [activeMedicationSchema, setActiveMedicationSchema] =
    useState<MedicationFormData>()
  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    validateForm,
  } = useFormikContext<MedicationSchedule>()

  // -- Functions --
  const handleNext = () => {
    setFieldTouched('medicationSchema', true)
    validateForm().then((errors) => {
      // No errors after validating, proceed to next step
      if (errors?.medicationSchema === undefined) {
        localStorage.setItem(LocalStorageKeys.FormState, JSON.stringify(values))
        onNext()
      }
    })
  }

  const startAddMedication = () => {
    setActiveMedicationSchema({
      id: uuidv4(),
      dosage: {
        dose: 1,
        times: 1,
      },
      interval: {
        interval: 1,
        intervalType: IntervalType.Days,
        intervalLabel: t('form.interval.days'),
      },
    })
  }

  const handleAddMedication = (
    medicationData: MedicationFormData,
    continueAdding: boolean,
  ) => {

    ReactGA.event({
      category: 'MedicationSchedule',
      action: 'Adding medication',
      label: 'Init',
  });

    if (medicationData.order !== undefined) {
      const index = values.medicationSchema.findIndex(
        (schema) => schema.order === medicationData.order,
      )
      setFieldValue(
        'medicationSchema',
        values.medicationSchema.map((schema, i) =>
          i === index ? medicationData : schema,
        ),
      )
    } else {
      setFieldValue('medicationSchema', [...values.medicationSchema, { ...medicationData, order: values.medicationSchema.length + 1 }])
    }

    if (continueAdding) {
      startAddMedication()
    } else {
      setActiveMedicationSchema(undefined)
    }
  }
  return (
    <StepLayout
      title={
        activeMedicationSchema
          ? t('form.medication.add')
          : t('form.medication.title')
      }
    >
      {activeMedicationSchema ? (
        <MedicationForm
          value={activeMedicationSchema}
          onAdd={handleAddMedication}
          onClose={() => setActiveMedicationSchema(undefined)}
        />
      ) : (
        <>
          <div className="flex flex-col gap-4 md:gap-12">
            <p className="font-body2 md:font-body1 text-text-neutral whitespace-pre-line">
              {t('form.medication.description')}
            </p>

            {touched?.medicationSchema && errors?.medicationSchema ? (
              <div className="flex justify-center items-center bg-red-50 rounded-md p-6">
                <ErrorMessage
                  name="medicationSchema"
                  component="div"
                  className="text-error"
                />
              </div>
            ) : null}

            {values?.medicationSchema.map((medication, index) => (
              <MedicationCard
                key={index}
                medication={medication}
                onEdit={() => setActiveMedicationSchema(medication)}
                onDelete={() =>
                  setFieldValue(
                    'medicationSchema',
                    values.medicationSchema.filter((_, i) => i !== index),
                  )
                }
              />
            ))}
          </div>

          <div className="flex justify-between pb-8">
            <Button
              variant={
                values.medicationSchema.length > 0 ? 'tertiary' : 'primary'
              }
              prefixIcon={
                values.medicationSchema.length > 0 ? null : (
                  <General.Plus className="stroke-base-white" />
                )
              }
              onClick={startAddMedication}
            >
              <p className="font-body2">{t('form.medication.add')}</p>
            </Button>

            {values.medicationSchema.length > 0 ? (
              <Button variant="primary" onClick={handleNext}
                disabled={!!errors.medicationSchema}
              >
                <p className="font-body2">{t('form.next')}</p>
              </Button>
            ) : null}
          </div>
        </>
      )}
    </StepLayout>
  )
}
