import type { Options } from 'react-markdown'
import ReactMarkdown from 'react-markdown'

type Props = {
  markdownText?: string
}

export const MarkdownRenderer = ({ markdownText }: Props) => {
  const regex = /\*\*(.*?)\*\*/g
  const fixedText = markdownText?.replace(regex, (match, group) => {
    if (group.startsWith(' ')) {
      return ` **${group.trim()}**`
    }

    return match
  })

  // Define custom components for styling Markdown elements
  const customComponents: Options['components'] = {
    h1: ({ node, ...props }) => <h1 className="font-title3 md:font-title2" {...props} />,
    h2: ({ node, ...props }) => <h2 className="font-body2 md:font-body1" {...props} />,
    h3: ({ node, ...props }) => <h3 className="font-body2 md:font-body1" {...props} />,
    h4: ({ node, ...props }) => <h4 className="font-body2 md:font-body1" {...props} />,
    h5: ({ node, ...props }) => <h5 className="font-body2 md:font-body1" {...props} />,
    h6: ({ node, ...props }) => <h6 className="font-body2 md:font-body1" {...props} />,
    p: ({ node, ...props }) => <p className="font-tag md:font-body2" {...props} />,
    a: ({ node, ...props }) => <a className="font-tag md:font-body2" {...props} />,
    ul: ({ node, ...props }) => <ul className="list-disc ml-5 font-tag md:font-body2" {...props} />,
    ol: ({ node, ...props }) => <ol className="list-decimal ml-5 font-tag md:font-body2" {...props} />,
    li: ({ node, ...props }) => <li className="mb-2 font-tag md:font-body2" {...props} />,
    em: ({ node, ...props }) => <em className="italic" {...props} />,
    strong: ({ node, ...props }) => <strong className="font-bold" {...props} />,
    // @ts-expect-error inline not supported?
    code: ({ node, inline, ...props }) => {
      if (inline) {
        return <code className="font-mono">{props.children}</code>
      }
      return (
        <pre className="p-2 bg-gray-100 rounded-md">
          <code className="font-mono block">{props.children}</code>
        </pre>
      )
    },
  }

  return (
    <ReactMarkdown components={customComponents} rehypePlugins={[]}>
      {fixedText}
    </ReactMarkdown>
  )
}
