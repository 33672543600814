import {
  useMutation,
  useQueryClient
} from 'react-query'

import { MedicationSchedule, GenderType, SubmitMedicationPayload } from '../form/form.types'
import { useAxios } from '@/hooks/useAxios'

export type GenderResponse = GenderType[]

export const useSubmitMedicationSchedule = () => {
  const axios = useAxios()
  const url = 'medicationschedule/add'

  const queryClient = useQueryClient()

  const { mutate, isLoading, error } = useMutation(
    async (data: SubmitMedicationPayload) => {
      const response = await axios.post(url, data)
      return response.data
    },
    {
      onError: () => {
        console.error('Error submitting schedule')
      },
      onSettled: () => {
        queryClient.invalidateQueries('medicationschedule')
      }
    }
  )

  return { mutate, isLoading, error }
}
