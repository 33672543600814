import { NumericInput } from '../input/NumericInput'
import { ButtonGroup } from '../button-group/ButtonGroup'
import { useTranslation } from 'react-i18next'
import { MultiSelect } from '../multi-select/MultiSelect'
import { Interval, IntervalType, Weekdays } from '../forms/medication.types'

type Props = {
  value: Interval
  onChange: (value: Interval) => void
}

export const IntervalInput = ({ value, onChange }: Props) => {
  const { t } = useTranslation()

  const updateInterval = (interval: number) => {
    onChange({ ...value, interval })
  }

  const updateIntervalType = (intervalType: {
    value: IntervalType
    label: string
  }) => {
    if (intervalType.value !== IntervalType.Weeks) {
      onChange({
        ...value,
        intervalType: intervalType.value,
        intervalLabel: intervalType.label,
        weekdays: undefined,
      })
    } else {
      onChange({
        ...value,
        intervalType: intervalType.value,
        intervalLabel: intervalType.label,
      })
    }
  }

  const updateWeekdays = (weekdays?: Weekdays[]) => {
    const sortedWeekdays = weekdays.sort(sortByWeekdayOrder)
    onChange({ ...value, weekdays: sortedWeekdays })
  }

  return (
    <div className="flex flex-grow flex-col gap-6">
      <div className="flex flex-wrap md:flex-row flex-grow align-center gap-4">
        <NumericInput
          fieldName=''
          value={value.interval}
          onChange={updateInterval}
          min={1}
          max={999}
        />
        <ButtonGroup
          selected={{
            value: value?.intervalType,
            label: value?.intervalLabel
          }}
          onChange={updateIntervalType}
          options={[
            {
              value: IntervalType.Days,
              label: t('form.medication.interval.days', {
                count: value.interval,
              }),
            },
            {
              value: IntervalType.Weeks,
              label: t('form.medication.interval.weeks', {
                count: value.interval,
              }),
            },
            {
              value: IntervalType.Months,
              label: t('form.medication.interval.months', {
                count: value.interval,
              }),
            },
          ]}
        />
      </div>

      {value.intervalType === IntervalType.Weeks ? (
        <MultiSelect
          selectedOptions={value.weekdays || []}
          onChange={updateWeekdays}
          options={[
            { label: t('monday_abbreviated'), value: Weekdays.Monday },
            { label: t('tuesday_abbreviated'), value: Weekdays.Tuesday },
            { label: t('wednesday_abbreviated'), value: Weekdays.Wednesday },
            { label: t('thursday_abbreviated'), value: Weekdays.Thursday },
            { label: t('friday_abbreviated'), value: Weekdays.Friday },
            { label: t('saturday_abbreviated'), value: Weekdays.Saturday },
            { label: t('sunday_abbreviated'), value: Weekdays.Sunday },
          ]}
        />
      ) : null}
    </div>
  )
}

function sortByWeekdayOrder(a: Weekdays, b: Weekdays) {
  const weekdaysOrder = [
    Weekdays.Monday,
    Weekdays.Tuesday,
    Weekdays.Wednesday,
    Weekdays.Thursday,
    Weekdays.Friday,
    Weekdays.Saturday,
    Weekdays.Sunday,
  ]
  return weekdaysOrder.indexOf(a) - weekdaysOrder.indexOf(b)
}
