import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import { LocalStorageKeys } from '@/constants/LocalStorageKeys'
import { Languages } from '../types/Languages'

export const useLanguage = () => {
  const { t, i18n } = useTranslation()

  const language = localStorage.getItem(LocalStorageKeys.SelectedLanguage) || Languages.nl
  const algoliaLanguage = language.slice(0, 1).toUpperCase() + language.slice(1, 2)

  // -- Data --
  const changeLanguage = async (language?: string) => {
    if (!language) { return }
    try {
      i18n.changeLanguage(language)
      localStorage.setItem(LocalStorageKeys.SelectedLanguage, language)
    } catch (error) {
      console.error(error)
      toast.error(t('language.error'), {})
    }
  }

  return { language, algoliaLanguage, changeLanguage }
}
