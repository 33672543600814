import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { EXPIRATION_TIME, LocalStorageKeys } from '@/constants/LocalStorageKeys'

// Define the shape of your form data
interface FormState {
  step: FormSteps
  steps: StepInfo[]
  stepIndex: number
  totalSteps: number
  showTermsAndConditions: boolean
}

// Define the shape of the context
interface FormContextType {
  resetForm: () => void
  handleNext: () => void
  handlePrevious: () => void
  setCurrentStep: (step: FormSteps) => void
  toggleTermsAndConditions: () => void
}

export enum FormSteps {
  Intro = 'intro',
  Medication = 'medication',
  Patient = 'patient',
  Report = 'report',
}

export type StepInfo = {
  key: FormSteps
  title?: string
  description?: string
  next?: FormSteps
  previous?: FormSteps
  hideInMenu?: boolean
}

const formSteps: Record<FormSteps, StepInfo> = {
  [FormSteps.Intro]: {
    key: FormSteps.Intro,
    next: FormSteps.Medication,
    hideInMenu: true,
  },
  [FormSteps.Medication]: {
    key: FormSteps.Medication,
    next: FormSteps.Patient,
    title: 'steps.medication.title',
  },
  [FormSteps.Patient]: {
    key: FormSteps.Patient,
    next: FormSteps.Report,
    previous: FormSteps.Medication,
    title: 'steps.patient.title',
    description: 'steps.patient.description',
  },
  [FormSteps.Report]: {
    key: FormSteps.Report,
    next: FormSteps.Medication,
    title: 'steps.report.title',
  },
}

// Create the context
const FormContext = createContext<(FormContextType & FormState) | undefined>(
  undefined,
)

// Hook to access the context
export const useFormContext = () => {
  const context = useContext(FormContext)
  if (!context) {
    throw new Error('useFormContext must be used within a FormContextProvider')
  }
  return context
}

// Define your FormContextProvider component
const FormContextProvider = ({ children }) => {
  // -- State --
  const [formState, setFormState] = useState<FormState>(() => {
    const storedFormState = localStorage.getItem(LocalStorageKeys.FormState)
    return storedFormState ? JSON.parse(storedFormState) : {
      step: FormSteps.Intro,
      steps: Object.entries(formSteps).map(([key, value]) => {
        return { key, ...value }
      }),
      stepIndex: 0,
      totalSteps: 0,
    }
  })

  // Handlers
  const setCurrentStep = (step: FormSteps) => {
    setFormState((prevState) => ({ ...prevState, step }))
  }

  const handleNext = () => {
    const nextStep = formSteps[formState.step].next
    setCurrentStep(nextStep)
    localStorage.setItem(LocalStorageKeys.ExpirationTime, JSON.stringify((new Date().getTime() + EXPIRATION_TIME)))
  }

  const handlePrevious = () => {
    const previousStep = formSteps[formState.step].previous
    setCurrentStep(previousStep)
    localStorage.setItem(LocalStorageKeys.ExpirationTime, JSON.stringify((new Date().getTime() + EXPIRATION_TIME)))
  }

  const resetForm = () => {
    setFormState((prevState) => ({
      ...prevState,
      step: FormSteps.Intro,
      data: undefined,
      patientId: undefined,
    }))
  }

  const toggleTermsAndConditions = () => {
    setFormState((prevState) => ({
      ...prevState,
      showTermsAndConditions: !prevState.showTermsAndConditions,
    }))
  }

  // -- Effects --
  // Update step index when step changes
  useEffect(() => {
    const trackedSteps = Object.keys(formSteps).filter(
      (key) => key !== FormSteps.Intro,
    )
    setFormState({
      ...formState,
      totalSteps: trackedSteps.length,
      stepIndex: trackedSteps.findIndex((step) => step === formState.step),
    })
  }, [formState.step])

  // Save form data to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem(LocalStorageKeys.FormState, JSON.stringify(formState));
  }, [formState]);

  // Load local storage on mount
  useEffect(() => {
    // Skip the intro step when needed
    if (localStorage.getItem(LocalStorageKeys.IntroViewed) && formState.step === FormSteps.Intro) {
      handleNext();
    }
  }, []);


  return (
    <FormContext.Provider
      value={{
        step: formState.step,
        steps: formState.steps,
        stepIndex: formState.stepIndex,
        totalSteps: formState.totalSteps,
        showTermsAndConditions: formState.showTermsAndConditions,
        resetForm,
        handleNext,
        handlePrevious,
        setCurrentStep,
        toggleTermsAndConditions,
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

export default FormContextProvider
