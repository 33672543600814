import { MedicationSchedule } from '@/form/form.types'
import { useFormikContext } from 'formik'
import React, { useState } from 'react'
import { getErrorMessage, getFieldTouched } from '../utils/formik'

interface TextInputProps {
  value: string
  label?: string
  min?: number
  max?: number
  fieldName: string
  onChange?: (newValue: string) => void
  onBlur?: () => void
}

type InputType = 'text' | 'number' | 'email'

export const TextInput = ({
  value,
  label,
  min,
  max,
  fieldName,
  onChange,
  onBlur,
  type,
}: TextInputProps & { type: InputType }) => {
  const { errors, touched, setFieldValue, setFieldTouched } =
    useFormikContext<MedicationSchedule>()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(fieldName, event.target.value)
    onChange && onChange(event.target.value)
  }

  const handleBlur = () => {
    setFieldTouched(fieldName, true)
    onBlur && onBlur()
  }


  const errorMessage = getErrorMessage(errors, fieldName)
  const showError = errorMessage && getFieldTouched(touched, fieldName)

  return (
    <div className="flex flex-col gap-2">
      {label ? <label>{label}</label> : null}
      <input
        type={type}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        min={min}
        max={max}
        className={`min-h-[50px] border ${showError ? 'border-error' : 'border-neutral-100'} rounded-md px-4 py-2 bg-neutral-100 focus:outline-none focus:ring-2 focus:ring-blue-500`}
      />
      {showError ? (
        <span className="text-error">{errorMessage}</span>
      ) : null}
    </div>
  )
}
