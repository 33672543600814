import { Severity } from '@/form/form.types'

type GridCellProps = {
  count?: number
  severity?: Severity
  highlighted?: boolean
}

export const GridCell = ({ count, severity, highlighted }: GridCellProps) => {
  const showPlaceholder = !count || count === 0

  return (
    <div className={`cell ${getBackgroundColor(severity, highlighted)}`}>
      {showPlaceholder ? (
        <p className="opacity-30">-</p>
      ) : (
        <p className={`font-semibold ${getTextColor(severity, highlighted)}`}>
          {!showPlaceholder ? count : '-'}
        </p>
      )}
    </div>
  )
}

type GridHeaderProps = {
  data?: string
  severity?: Severity
}

export const GridHeader = ({ data, severity }: GridHeaderProps) => {
  return (
    <div className={`cell-sm md:cell ${getBackgroundColor(severity, true)}`}>
      <p className={`font-semibold ${getTextColor(severity, true)}`}>
        {data}
      </p>
    </div>
  )
}

const getBackgroundColor = (severity?: Severity, highlighted?: boolean) => {
  switch (severity) {
    case Severity.A:
      return highlighted ? `bg-severity-a` : `bg-severity-a bg-opacity-50`
    case Severity.B:
      return highlighted ? `bg-severity-b` : 'bg-severity-b bg-opacity-50'
    case Severity.C:
      return highlighted ? `bg-severity-c` : 'bg-severity-c bg-opacity-50'
    case Severity.D:
      return highlighted ? `bg-severity-d` : 'bg-severity-d bg-opacity-50'
    default:
      return highlighted
        ? `bg-severity-total`
        : 'bg-severity-total bg-opacity-50'
  }
}

const getTextColor = (severity?: Severity, highlighted?: boolean) => {
  switch (severity) {
    case Severity.A:
      return highlighted ? `text-text-severity-a` : `text-neutral`
    case Severity.B:
      return highlighted ? `text-text-severity-b` : `text-neutral`
    case Severity.C:
      return highlighted ? `text-text-severity-c` : `text-neutral`
    case Severity.D:
      return highlighted ? `text-text-severity-d` : `text-neutral`
    default:
      return `text-neutral`
  }
}
