import { AnalyzerResult, CategoryType, Severity } from '@/form/form.types'
import { ReportCard } from '../report-card/ReportCard'
import { Collapsible } from '../collapsible/Collapsible'
import { t } from 'i18next'
import { useState } from 'react'
import { Select, SelectItem } from '../input/Select'
import { useCategories } from '@/api/useCategories'

type GroupedAnalyzerResults = {
  category: string
  data: AnalyzerResult[]
}

type Props = {
  analysis: AnalyzerResult[]
  categories: CategoryType[]
}

export const Report = ({ analysis, categories }: Props) => {

  const [selectedCategory, setSelectedCategory] = useState<SelectItem>()
  const [selectedSeverity, setSelectedSeverity] = useState<SelectItem>()

  // Interaction Methods
  // --------------------------------------------------------
  const handleCategoryChange = (value: SelectItem) => {
    setSelectedCategory(value)
  }

  const handleSeverityChange = (value: SelectItem) => {
    setSelectedSeverity(value)
  }

  // Template variables
  // --------------------------------------------------------
  const filteredAnalysis = analysis.filter((item) => {
    return (
      (!selectedCategory || item.category === selectedCategory.value) &&
      (!selectedSeverity || item.severity === selectedSeverity.value)
    )
  })

  const groupedAnalysisCards = groupAnalysisCards(filteredAnalysis, categories)

  const availableSeverities = Object.entries(Severity)
    .map(([key, value]) => ({
      value: value,
      label: key,
    }))
    .filter((item) => analysis.some((entry) => entry.severity === item.value))

  const availableCategories = categories?.filter((item) => analysis.some((entry) => entry.category === item.name))

  // Render
  // --------------------------------------------------------
  return (
    <div className="mt-6">
      {availableSeverities.length <= 1 &&
        availableCategories.length <= 1 ? null : (
        <div className="filters flex justify-end items-center gap-4 mb-4">
          <p className="font-body-2">{t('steps.report.filter.label')}</p>
          {availableSeverities.length <= 1 ? null : (
            <Select
              controlClassNames='min-w-[140px]'
              value={selectedSeverity}
              placeholder={t('steps.report.filter.severity.placeholder')}
              onChange={handleSeverityChange}
              isClearable={true}
              options={availableSeverities}
            />
          )}
          {availableCategories.length <= 1 ? null : (
            <Select
              controlClassNames='min-w-[180px]'
              value={selectedCategory}
              placeholder={t('steps.report.filter.category.placeholder')}
              onChange={handleCategoryChange}
              isClearable={true}
              options={availableCategories.map(category => ({
                value: category.name,
                label: category.localisedName
              }))}
            />
          )}
        </div>
      )}
      {
        analysis.length === 0 ? (<p className='font-body2'>{t('steps.report.empty')}</p>) :
          groupedAnalysisCards.length === 0 ? (
            <p className="font-body2">{t('steps.report.empty.filter')}</p>
          ) : (
            groupedAnalysisCards.map((group: GroupedAnalyzerResults, index) => (
              <div key={group.category} className="flex flex-col gap-5">
                <div className="h-0 border border-solid border-base-black opacity-10" />

                <Collapsible title={group.category} analysis={group.data}>
                  {group.data.map((result, index) => (
                    <ReportCard key={index} reportEntry={result} />
                  ))}
                </Collapsible>
              </div>
            ))
          )}
    </div>
  )
}

const groupAnalysisCards = (analysis: AnalyzerResult[], categories: CategoryType[]) => {
  const resultMap = new Map<string, AnalyzerResult[]>()

  for (const item of analysis) {
    const categoryName = categories.find((category) => category.name === item.category)?.localisedName
    if (!resultMap.has(categoryName)) {
      resultMap.set(categoryName, [])
    }
    resultMap.get(categoryName)?.push(item)
  }

  const groupedByCategoryData: GroupedAnalyzerResults[] = []
  for (const [category, data] of resultMap.entries()) {
    groupedByCategoryData.push({ category, data })
  }

  return groupedByCategoryData
}
