import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

export const useFormValidation = () => {
  const { t } = useTranslation()

  // -- ValidationSchema --
  const PatientValidation = Yup.object().shape({
    birthYear: Yup.number().min(1900, t('form.invalid.birthYear')).max(new Date().getFullYear(), t('form.invalid.birthYear')),
    gender: Yup.object().shape({
      value: Yup.string(),
      label: Yup.string(),
    }),
  })

  const HealthcareProfessionalValidation = Yup.object().shape({
    emailAddress: Yup.string().email(t('form.invalid.email')),
    type: Yup.object().shape({
      value: Yup.string(),
      label: Yup.string(),
    }),
  })

  return Yup.object().shape({
    medicationSchema: Yup.array().min(1, t('form.medication.requiredArray')),
    patient: PatientValidation,
    healthcareProfessional: HealthcareProfessionalValidation,
  })
}