import {
  useQuery
} from 'react-query'

import { StaleTime } from '@/constants/Staletime'
import { useAxios } from '@/hooks/useAxios'
import { useLanguage } from '@/translations/hooks/useLanguage'

export type MedicationProfessionalsResponse = { values: string[] }

export const useMedicationProfessionals = () => {
  const axios = useAxios()
  const { language } = useLanguage()

  const url = 'medicationprofessionals'

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['medicationprofessionals', language],
    queryFn: async () => {
      const response = await axios.get<MedicationProfessionalsResponse>(url)
      return response.data
    },
    select: (response) => {
      return response.values?.map((medicationProfessional, index) => ({
        value: medicationProfessional,
        label: medicationProfessional
      }))
    },
    staleTime: StaleTime.ONE_HOUR,
  })

  return { medicationProfessionals: data, isLoading, error, refetch }
}
