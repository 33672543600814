import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App'
import './index.css'
import { ConfigProvider } from './config.js'
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next'
import { initTranslations } from './translations/utils/innitTranslation'
import { Toaster } from 'react-hot-toast'
import FormContextProvider from './form/context/FormContextProvider'
import ReactGA from 'react-ga4';

const queryClient = new QueryClient()
initTranslations()
ReactGA.initialize('G-4JN1YM2D55');
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next} defaultNS={'translation'}>
      <ConfigProvider>
        <QueryClientProvider client={queryClient}>
          <Toaster
            position="bottom-center"
            reverseOrder={false}
            toastOptions={{
              duration: 5000,
              success: {
                style: {
                  background: '#00b894',
                  color: '#fff',
                },
              },
              error: {
                style: {
                  background: '#d63031',
                  color: '#fff',
                },
              },
            }}
          />
          <FormContextProvider>
            <App />
          </FormContextProvider>
        </QueryClientProvider>
      </ConfigProvider>
    </I18nextProvider>
  </React.StrictMode>,
)
