import { useFormContext } from '../context/FormContextProvider'
import { useTranslation } from 'react-i18next'

export const ProgressIndicator = () => {
  const { t } = useTranslation()
  const { steps, step } = useFormContext()

  const visibleSteps = steps.filter((cur) => !cur.hideInMenu)
  const stepIndex = visibleSteps.findIndex((cur) => cur.key === step)

  const sharedStyle =
    'w-10 h-10 flex justify-center items-center relative md:rounded-md z-10 transition-colors duration-500 border-2'

  return (
    <>
      <div className="hidden md:flex justify-center md:flex-col flex-grow gap-8 md:justify-start">
        {visibleSteps.map((step, index) => (
          <div
            className="py-1 flex flex-col items-center gap-4 select-none md:flex-row"
            key={index}
          >
            {/* Current */}
            {stepIndex === index ? (
              <div
                className={`${sharedStyle} text-base-white bg-primary border-primary`}
              >
                <span className="font-sidebar">{index + 1}</span>
              </div>
            ) : // Previous
              stepIndex > index ? (
                <div
                  className={`${sharedStyle} text-base-white bg-base-white border-primary`}
                >
                  <img src="/check.svg" alt="check" className="w-8 h-8" />
                </div>
              ) : // Next
                stepIndex < index ? (
                  <div
                    className={`${sharedStyle} text-text-primary bg-neutral-100 border-neutral-100`}
                  >
                    <span className="font-sidebar">{index + 1}</span>
                  </div>
                ) : null}

            <div className="hidden md:flex flex-col flex-grow gap-1">
              <p
                className={`font-body2 text-text-primary ${stepIndex === index ? 'font-bold' : ''}`}
              >
                {t(step.title)}
              </p>
              {step?.description ? (
                <p className="font-tag text-text-neutral">
                  {t(step.description)}
                </p>
              ) : null}
            </div>
          </div>
        ))}
      </div>

      <div className='flex md:hidden'>
        <div className="w-full bg-neutral-200 h-2">
          <div
            className="bg-primary h-2 transition-all duration-500"
            style={{ maxWidth: '100%', width: `${((stepIndex + 1) / visibleSteps.length) * 100}%` }}
          ></div>
        </div>
      </div>
    </>
  )
}
