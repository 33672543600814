import { Button } from "@/components/button/Button"
import { StepLayout } from "@/components/layouts/StepLayout"
import { LocalStorageKeys } from "@/constants/LocalStorageKeys"
import { useTranslation } from "react-i18next"
import { Navigate } from "react-router-dom"
import { useFormContext } from "../context/FormContextProvider"

export const IntroStep = () => {
  const { t } = useTranslation()
  const {
    handleNext,
  } = useFormContext()

  const onNext = () => {
    localStorage.setItem(LocalStorageKeys.IntroViewed, 'true')
    handleNext()
  }

  // Don't render if it shouldn't
  if (localStorage.getItem(LocalStorageKeys.IntroViewed)) {
    return null
  }

  return (
    <StepLayout title={t('intro.title')}>
      <p className='font-body2 md:font-body1 text-text-neutral whitespace-pre-line'>
        {t('intro.description')}
      </p>

      <Button onClick={onNext}>{t('intro.start')}</Button>
    </StepLayout>
  )
}