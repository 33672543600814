import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import trans_en from '../locales/en.json'
import trans_fr from '../locales/fr.json'
import trans_nl from '../locales/nl.json'
import { LocalStorageKeys } from '@/constants/LocalStorageKeys'

// This needs to be called as soon as possible outside of the react tree
export const initTranslations = () => {
  i18next.use(initReactI18next).init({
    interpolation: { escapeValue: false },
    lng: localStorage.getItem(LocalStorageKeys.SelectedLanguage) || 'nl-BE',
    resources: {
      "en-GB": { translation: trans_en },
      "fr-BE": { translation: trans_fr },
      "nl-BE": { translation: trans_nl },
    },
  })
}
