import React, { ReactNode } from 'react'

type StepLayoutProps = {
  title: ReactNode
  children: ReactNode
  className?: string
}

export const StepLayout: React.FC<StepLayoutProps> = ({ title, children }) => {
  return (
    <div className={`flex flex-col flex-grow gap-3 w-full min-h-0 relative md:max-w-[520px] lg:max-w-[780px]`}>
      <div className="flex flex-shrink-0">
        <h1 className='font-title2 md:font-title1 font-semibold text-base-black'>{title}</h1>
      </div>
      <div className="flex flex-grow w-full min-h-0 flex-col gap-8 md:gap-16">{children}</div>
    </div>
  )
}