import { NumericInput } from '../input/NumericInput'
import { useTranslation } from 'react-i18next'
import { Dosage } from '../forms/medication.types'

type Props = {
  value: Dosage
  onChange: (value: Dosage) => void
}

export const DosageInput = ({ value, onChange }: Props) => {
  const { t } = useTranslation()

  const updateDose = (dose: number) => {
    onChange({ ...value, dose })
  }

  const updateTimes = (times: number) => {
    onChange({ ...value, times })
  }

  return (
    <div className="flex items-center gap-4">
      <NumericInput
        value={value.times}
        onChange={(value) => updateTimes(value)}
        min={1}
        max={999}
      />
      <span className="font-body2">{t('form.medication.dosage.times')}</span>
      <NumericInput
        value={value.dose}
        onChange={(value) => updateDose(value)}
        min={1}
        max={999}
      />
      <span className="font-body2">{t('form.medication.dosage.dose')}</span>
    </div>
  )
}
