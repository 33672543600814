import fs from 'fs';
import { useTranslation } from 'react-i18next'
import { StepLayout } from '../layouts/StepLayout'
import { Button } from '../button/Button'
import { useState } from 'react'
import { General } from 'untitledui-js'
import { useLanguage } from '@/translations/hooks/useLanguage'
import { MarkdownRenderer } from '../markdown/MarkdownRenderer';

type Props = {
  onAccept: () => void
}

export const TermsAndConditionsForm = ({ onAccept }: Props) => {
  const { t } = useTranslation()
  const { language } = useLanguage()

  const [checked, setChecked] = useState(false)

  return (
    <StepLayout title={t('steps.termsAndConditions.title')}>
      <div className="flex flex-col min-h-0 flex-grow gap-6 md:gap-10">
        <div className="flex flex-col gap-4 flex-grow overflow-y-auto bg-neutral-100 rounded-lg px-4 md:px-6 py-4 md:py-10">
          <MarkdownRenderer
            markdownText={t('termsAndConditions')
            }
          />
        </div>

        <div
          className="flex gap-4 cursor-pointer"
          onClick={() => {
            setChecked((cur) => !cur)
          }}
        >
          <div
            className={`w-6 h-6 rounded-md border border-solid border-base-black ${checked ? 'bg-base-black' : 'bg-base-white border-opacity-15'}`}
          >
            {checked ? <General.Check className="stroke-base-white" /> : null}
          </div>
          <p className="font-tag md:font-body2 select-none">
            {t('steps.termsAndConditions.accept')}
          </p>
        </div>
      </div>

      <Button variant="primary" onClick={onAccept} disabled={!checked}>
        {t('steps.termsAndConditions.startAnalysis')}
      </Button>
    </StepLayout >
  )
}
