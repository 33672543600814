import { SupportedLanguages } from "@/translations/types/Languages"
import ReactSelect, { OptionProps, ValueContainerProps, components } from 'react-select'
import { useLanguage } from "@/translations/hooks/useLanguage"

export const LanguageSelect = () => {
  const { language, changeLanguage } = useLanguage()

  const ValueContainer = ({ children, ...props }) => {
    return (
      <div className="flex px-3 items-center">
        <img src='/globe.svg' alt='globe' className='w-[18px] h-[18px]' />
        <components.ValueContainer {...props as ValueContainerProps} className="!p-0 md:!pl-1 md:!flex md:!items-center">
          <div className="hidden md:block">{children[0]}</div>
          {children[1]}
        </components.ValueContainer>
      </div>
    )
  }

  const Option = ({ data, ...props }) => {
    return (
      <components.Option {...props as OptionProps}
        className={`hover:bg-neutral-400 !cursor-pointer ${props.isSelected ? '!bg-primary' : ''}`}
      >
        <span className="hidden md:block">{data.label}</span>
        <span className="md:hidden">{data.shortLabel}</span>
      </components.Option>
    )
  }


  return <ReactSelect
    classNames={{
      control: () => '!border-none !bg-[#0000000D] !cursor-pointer',
      menu: () => '!my-0.5',
      menuList: () => '!p-0 rounded-md',
    }}
    value={SupportedLanguages.find((lang) => lang.value === language)}
    components={{
      ValueContainer,
      IndicatorsContainer: () => null,
      IndicatorSeparator: () => null,
      Option
    }}
    isSearchable={false}
    isClearable={false}
    options={SupportedLanguages}
    onChange={(value) => {
      changeLanguage(value.value as string)
    }} />
}