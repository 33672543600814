import { AnalyzerResult, CategoryType, Severity } from '@/form/form.types'
import { t } from 'i18next'
import { GridCell, GridHeader } from './GridCell'
import { Fragment } from 'react'

type TransformedData = {
  category: string
  data: AnalyzerResult[]
}

type Row = {
  category: string
  severityACount: number
  severityBCount: number
  severityCCount: number
  severityDCount: number
  totalCount: number
}

type RowData = {
  total: Row,
  rows: Row[],
}

type Props = {
  data: AnalyzerResult[]
  categories: CategoryType[]
}

export const CrossGrid = ({ data, categories }: Props) => {
  const { rows, total } = getRowData(data, categories)

  return (
    <div className={`grid grid-cols-severity-data-row gap-0.5 select-none`}>
      {/* Headers */}
      <div />
      {Object.values(Severity).map((severity) => {
        return (
          <GridHeader key={severity} severity={severity} data={severity} />
        )
      })}
      <GridHeader key={'total'} data={t('total')} />

      {/* Content */}
      {rows.map((row) => (
        <Fragment key={row.category}>
          <div className="cell-title-sm md:cell-title" >
            {row.category}
          </div>
          <GridCell count={row.severityDCount} severity={Severity.D} />
          <GridCell count={row.severityCCount} severity={Severity.C} />
          <GridCell count={row.severityBCount} severity={Severity.B} />
          <GridCell count={row.severityACount} severity={Severity.A} />
          <GridCell count={row.totalCount} />
        </Fragment>
      ))}

      {/* Total */}
      <div className="cell-title-sm md:cell-title" key={total.category}>
        {total.category}
      </div>
      <GridCell count={total.severityDCount} severity={Severity.D} highlighted />
      <GridCell count={total.severityCCount} severity={Severity.C} highlighted />
      <GridCell count={total.severityBCount} severity={Severity.B} highlighted />
      <GridCell count={total.severityACount} severity={Severity.A} highlighted />
      <GridCell count={total.totalCount} highlighted />
    </div>
  )
}

const getRowData = (inputData: AnalyzerResult[], categories: CategoryType[]): RowData => {
  const resultMap = new Map<string, AnalyzerResult[]>()

  // Create empty data map
  categories.forEach((category) => {
    const categoryName = category.localisedName
    resultMap.set(categoryName, [])
  })

  for (const item of inputData) {
    const categoryName = categories.find((category) => category.name === item.category)?.localisedName
    resultMap.get(categoryName)?.push(item)
  }

  const groupedByCategoryData: TransformedData[] = []
  for (const [category, data] of resultMap.entries()) {
    groupedByCategoryData.push({ category, data })
  }

  const result: Row[] = []
  for (const item of groupedByCategoryData) {
    result.push(countSeverity(item))
  }
  return {
    rows: result,
    total: {
      category: t('total'),
      severityACount: result.reduce((acc, curr) => acc + curr.severityACount, 0),
      severityBCount: result.reduce((acc, curr) => acc + curr.severityBCount, 0),
      severityCCount: result.reduce((acc, curr) => acc + curr.severityCCount, 0),
      severityDCount: result.reduce((acc, curr) => acc + curr.severityDCount, 0),
      totalCount: result.reduce((acc, curr) => acc + curr.totalCount, 0),
    }
  }
}

const countSeverity = (data: TransformedData): Row => {
  let severityACount = 0
  let severityBCount = 0
  let severityCCount = 0
  let severityDCount = 0

  for (const item of data.data) {
    switch (item.severity) {
      case Severity.A:
        severityACount++
        break
      case Severity.B:
        severityBCount++
        break
      case Severity.C:
        severityCCount++
        break
      case Severity.D:
        severityDCount++
        break
    }
  }

  const totalCount = data.data.length

  return {
    category: data.category,
    severityACount,
    severityBCount,
    severityCCount,
    severityDCount,
    totalCount,
  }
}
