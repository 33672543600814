const ONE_MINUTE = 60 * 1000

export enum StaleTime {
  MINUTE = ONE_MINUTE,
  FIVE_MIN = 5 * ONE_MINUTE,
  FIFTEEN_MIN = 15 * ONE_MINUTE,
  THIRTY_MIN = 30 * ONE_MINUTE,
  FORTY_FIVE_MIN = 45 * ONE_MINUTE,
  ONE_HOUR = 60 * ONE_MINUTE,
  FOREVER = Number.POSITIVE_INFINITY,
}