import { ProgressIndicator } from '../progress-indicator/ProgressIndicator'
import { useTranslation } from 'react-i18next'
import { useFormContext } from '../context/FormContextProvider'

export const Sidebar = () => {
  const { toggleTermsAndConditions } = useFormContext()

  const { t } = useTranslation()
  return (
    <div className="flex flex-col flex-grow space-between md:min-w-[300px]">
      <ProgressIndicator />
      <div onClick={toggleTermsAndConditions} className='hidden underline cursor-pointer md:block'>
        {t('steps.termsAndConditions.title')}
      </div>
    </div>
  )
}
