import {
  useQuery
} from 'react-query'

import { PersonType } from '../form/form.types'
import { StaleTime } from '@/constants/Staletime'
import { useAxios } from '@/hooks/useAxios'
import { useLanguage } from '@/translations/hooks/useLanguage'

export type PersonTypeResponse = PersonType[]

export const usePersonTypes = () => {
  const axios = useAxios()
  const { language } = useLanguage()

  const url = 'persontypes'

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['persontypes', language],
    queryFn: async () => {
      const response = await axios.get<PersonTypeResponse>(url)
      return response.data
    },
    select: (response) => {
      return response
        .map((persontype) => ({
          value: persontype.typeId,
          label: persontype.typeDescription
        }))
        .sort((a, b) => {
          // Sort by value, but put the "Other" at the end
          if (a.value === 0) {
            return 1
          }
          if (b.value === 0) {
            return -1
          }
          return a.label.localeCompare(b.label)
        })
    },
    staleTime: StaleTime.ONE_HOUR,
  })

  return { personTypes: data, isLoading, error, refetch }
}
