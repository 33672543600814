import type { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from 'react'
import { General } from 'untitledui-js'

type ButtonDefaultProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

type ButtonCustomProps = {
  children?: ReactNode
  /** @default primary */
  variant?: keyof typeof classnames.variant
  prefixIcon?: string | ReactNode
  postfixIcon?: string | ReactNode
  isLoading?: boolean
  full?: boolean
  onClick?: () => void
}

type Props = ButtonDefaultProps & ButtonCustomProps

const shared =
  'w-fit px-6 py-4 rounded-md flex items-center transition-colors duration-200 cursor-pointer select-none'
const classnames = {
  variant: {
    primary: 'shadow-sm bg-primary text-white group-hover:bg-primary-500  shadow-[0px_8px_12px_0px_#00000014,0px_2px_1px_0px_#E55E52,0px_2px_3px_0px_#FFB2AB_inset,0px_0px_0px_1px_#FF685B_inset]',
    primaryDisabled: 'shadow-sm bg-gray-50 text-gray-500 cursor-default  shadow-[0px_8px_12px_0px_#00000014,0px_2px_1px_0px_#E55E52,0px_2px_3px_0px_#FFB2AB_inset,0px_0px_0px_1px_#FF685B_inset]',
    tertiary: 'bg-neutral-100 text-text-primary',
    tertiaryDisabled: 'bg-none text-text-neutral cursor-default',
    error:
      'shadow-sm bg-error-600 text-base-white hover:bg-error-700 border border-solid border-error-600',
    errorOutline:
      'shadow-sm bg-base-white text-error-600 hover:bg-error-50 border border-solid border-error-600',
    errorDisabled: 'shadow-sm bg-text-gray-200 border-gray-200 cursor-default',
  },
}

const Button = ({
  children,
  variant = 'primary',
  disabled,
  prefixIcon,
  postfixIcon,
  isLoading,
  className: additionalClassnames = '',
  onClick,
  full = false,
  ...rest
}: Props) => {
  const variantKey = `${variant}${disabled ? 'Disabled' : ''
    }` as keyof typeof classnames.variant
  const variantClassname = classnames.variant[variantKey] || ''
  const fullClassname = full ? "w-full justify-center md:w-auto md:justify-start" : ''

  const classNamesToApply = `font-semibold ${shared} ${variantClassname} ${fullClassname} ${additionalClassnames}`

  return (
    <button
      className={classNamesToApply}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {isLoading ? (
        <General.Loading02
          className={`w-[22px] h-[22px] object-cover animate-spin ${['primary', 'secondary'].includes(variant)
            ? 'stroke-base-white'
            : 'stroke-gray-700'
            }
      `}
        />
      ) : (
        <>
          {prefixIcon ? (
            typeof prefixIcon === 'string' ? (
              <img
                src={prefixIcon}
                alt="itsme"
                className="w-6 h-auto object-cover"
              />
            ) : (
              prefixIcon
            )
          ) : null}
          {children}
          {postfixIcon ? (
            typeof postfixIcon === 'string' ? (
              <img
                src={postfixIcon}
                alt="itsme"
                className="w-6 h-auto object-cover"
              />
            ) : (
              postfixIcon
            )
          ) : null}
        </>
      )}
    </button>
  )
}

export { Button }
