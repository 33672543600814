import { MedicationFormData, Product } from "@/components/forms/medication.types"

// Algolia types
export type AlogliaMedication = {
  objectId: number
  productId: number
  productDescription_Nl: string
  productDescription_Fr: string
}

// API types
export type SubmitMedicationPayload = {
  healthcareProfessional: HealthcareProfessional,
  patient: Patient,
  detailLines: DetailLine[]
}

export type MedicationSchedule = {
  healthcareProfessional: HealthcareProfessional,
  patient: Patient,
  medicationSchema: MedicationFormData[]
}

export type HealthcareProfessional = {
  personId?: string,
  emailAddress?: string,
  type?: {
    value: number,
    label: string,
  },
}

export type Patient = {
  personId?: string
  birthYear?: number,
  gender?: {
    value: number,
    label: string,
  },
}

export type PersonType = {
  typeId?: number,
  typeDescription?: string
}

export type LanguageType = {
  languageId: number,
  languageDescription: string
}

export type GenderType = {
  genderId: number,
  genderDescription: string
}

export type DetailLine = {
  id: string,
  detailLineId?: number,
  addedOn?: string,
  product?: Product,
  interval?: number
  startDate?: string
  administrations?: Administration[]
}

export type Administration = {
  quantity: number,
  admninistrationTime?: AdministrationTime
}

export type AdministrationTime = {
  ticks: number
}

export type AnalyzerResult = {
  interaction: string
  intervention: string
  category: string
  severity: Severity
  products: Product[]
  quantity: number
}

export enum Severity {
  D = 'D',
  C = 'C',
  B = 'B',
  A = 'A'
}

// export enum Category {
//   'General' = 'General',
//   'Interactions' = 'Interactions',
//   'DoseCheck' = 'DoseCheck',
//   'ContaIndications' = 'ContraIndications',
//   'Gheops' = 'Gheops',
//   'LabValues' = 'LabValues',
//   'Deprescribing' = 'Deprescribing',
//   'Extra' = 'Extra'
// }

export type CategoryType = {
  id: number,
  name: string,
  localisedName: string
}