import { v4 as uuidv4 } from 'uuid';
import { useSubmitMedicationSchedule } from "@/api/useSubmitMedicationSchedule"
import { MedicationSchedule } from "../form.types"
import { LocalStorageKeys } from '@/constants/LocalStorageKeys';
import { Interval, IntervalType, Weekdays } from '@/components/forms/medication.types';

export const useMedicationSchedule = () => {
  const { mutate, isLoading, error } = useSubmitMedicationSchedule()

  const submit = (values: MedicationSchedule, onSuccess: () => void) => {
    if (!values.patient.personId) {
      values.patient.personId = uuidv4()
    }
    if (!values.healthcareProfessional.personId) {
      values.healthcareProfessional.personId = uuidv4()
    }

    localStorage.setItem(LocalStorageKeys.PatientId, values.patient.personId)
    localStorage.setItem(LocalStorageKeys.HealthCareProfessionalId, values.healthcareProfessional.personId)

    // Create mapping here
    const payload = {
      healthcareProfessional: {
        personId: values.healthcareProfessional.personId,
        emailAddress: values.healthcareProfessional?.emailAddress,
        // personType: values.healthcareProfessional?.type,
      },
      patient: {
        personId: values.patient.personId,
        birthYear: values.patient?.birthYear,
        // gender: values.patient.gender,
      },
      detailLines: values.medicationSchema.map((medication) => {
        return {
          id: medication.id,
          product: medication.product,
          dose: medication.dosage.dose,
          frequency: medication.dosage.times,
          interval: mapInterval(medication.interval),
          useWeekdays: medication.interval.weekdays?.length > 0,
          weekdays: mapWeekdays(medication.interval.weekdays)
        }
      })
    }

    mutate(payload, {
      onSuccess
    })
  }

  return {
    submit,
    isLoading,
    error
  }
}

const mapWeekdays = (weekdays: Weekdays[]) => {
  const weekdaysObject = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false
  };

  weekdays?.forEach(day => {
    weekdaysObject[day] = true;
  });

  return weekdaysObject
}

const mapInterval = (interval: Interval) => {
  if (interval.intervalType === IntervalType.Days) {
    return interval.interval
  } else if (interval.intervalType === IntervalType.Weeks) {
    return interval.interval * 7
  } else if (interval.intervalType === IntervalType.Months) {
    return interval.interval * 30
  }
  return 0
}