import { LocalStorageKeys } from '@/constants/LocalStorageKeys'
import { Form, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import isEqual from 'lodash/isEqual'

export const PersistedForm = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const { values, setValues, initialValues, validateForm } = useFormikContext()

  // Load form data from local storage when component mounts
  useEffect(() => {
    const storedData = localStorage.getItem(LocalStorageKeys.FormData)
    if (storedData) {
      setValues(JSON.parse(storedData))
    } else {
      setValues(initialValues)
    }
    setTimeout(() => validateForm(), 100)
    setIsLoaded(true)
  }, [setValues])

  // Update local storage whenever form values change
  useEffect(() => {
    if (isEqual(initialValues, values)) {
      return
    }

    localStorage.setItem(LocalStorageKeys.FormData, JSON.stringify(values));
  }, [values]);

  if (!isLoaded) {
    return null
  }

  return (
    <Form
      className="flex flex-grow justify-center"
      onSubmit={(e) => e.preventDefault()}
    >
      {children}
    </Form>
  )
}
