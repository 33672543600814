import { MedicationAutocomplete } from '../medication-autocomplete/MedicationAutocomplete'
import { Button } from '../button/Button'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { General } from 'untitledui-js'
import { DosageInput } from '../dosage-input/DosageInput'
import { IntervalInput } from '../interval-input/IntervalInput'
import {
  Dosage,
  Interval,
  IntervalType,
  MedicationFormData,
  Product,
} from './medication.types'
import toast from 'react-hot-toast'

type MedicationFormProps = {
  value: MedicationFormData
  onAdd: (value: MedicationFormData, continueAdding: boolean) => void
  onClose: () => void
}

export const MedicationForm = ({
  value,
  onAdd,
  onClose,
}: MedicationFormProps) => {
  const { t } = useTranslation()

  const isEditing = value?.product?.productId !== undefined

  const [product, setProduct] = useState<Product>(value.product)
  const [dosage, setDosage] = useState<Dosage>({
    dose: value.dosage?.dose || 1,
    times: value.dosage?.times || 1,
  })
  const [interval, setInterval] = useState<Interval>({
    interval: value.interval?.interval || 1,
    intervalType: value.interval?.intervalType || IntervalType.Days,
    intervalLabel: value.interval?.intervalLabel || t('form.interval.days'),
  })
  const [showError, setShowError] = useState(false)
  const isValid = !(!product || product?.productId === -1)

  // -- Effect --
  useEffect(() => {
    setProduct(value?.product)
    setDosage(value?.dosage)
    setInterval(value?.interval)
  }, [value.id])

  // -- Functions --
  const updateMedication = (continueAdding: boolean) => {
    if (!isValid) {
      setShowError(true)
      if (!continueAdding) {
        onClose()
      }
      return
    }

    toast.success(t('form.medication.success'), {})
    onAdd(
      {
        id: value?.id,
        order: value?.order,
        product,
        dosage,
        interval,
      },
      continueAdding,
    )
  }

  // -- Render --
  return (
    <div className="flex flex-col flex-grow gap-12 md:gap-16 pt-5 relative">
      <div className="flex flex-col gap-4 md:gap-8">
        {/* Medicine search */}
        <div className="flex flex-col gap-2 md:gap-8 pb-4 md:pb-2 md:items-center md:flex-row">
          <label
            htmlFor="medication-autocomplete"
            className={`font-title3 ${showError && !isValid ? 'text-error' : ''}`}
          >
            {t('form.medication.autocomplete.label')}
          </label>
          <MedicationAutocomplete
            value={product}
            onChange={(product) => {
              setShowError(false)
              setProduct(product)
            }}
            error={showError && !isValid ? t('form.required') : undefined}
          />
        </div>

        <div className="w-full h-[1px] bg-neutral-400" />

        {/* Dosage */}
        <div className="flex flex-col md:flex-row gap-2 md:gap-8 items-start">
          <label htmlFor="medication-autocomplete" className="font-title3 md:mt-4">
            {t('form.medication.dosage')}
          </label>
          <DosageInput value={dosage} onChange={setDosage} />
        </div>

        <div className="w-full h-[1px] bg-neutral-400" />

        {/* Interval */}
        <div className="flex flex-col md:flex-row gap-2 md:gap-8 items-start">
          <label htmlFor="medication-autocomplete" className="font-title3 md:mt-4">
            {t('form.medication.interval')}
          </label>
          <IntervalInput value={interval} onChange={setInterval} />
        </div>
      </div>

      <div className="flex flex-wrap gap-2 pb-8">
        <Button
          full
          variant="primary"
          prefixIcon={<General.Plus className="stroke-base-white mr-1" />}
          onClick={() => updateMedication(!isEditing)}
        >
          <p className="font-tag md:font-body2">
            {isEditing
              ? t('form.medication.edit')
              : t('form.medication.addExtra')}
          </p>
        </Button>

        {!isEditing ? (
          <Button
            full
            variant={'tertiary'}
            prefixIcon={<General.Check className="stroke-base-black mr-1" />}
            onClick={() => updateMedication(false)}
          >
            <p className="font-tag md:font-body2">{t('form.medication.ready')}</p>
          </Button>
        ) : null}
      </div>
    </div>
  )
}
