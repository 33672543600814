import { useTranslation } from 'react-i18next'
import { General } from 'untitledui-js'
import { MedicationFormData } from '../forms/medication.types'

type Props = {
  medication: MedicationFormData
  onEdit: () => void
  onDelete: () => void
}

export const MedicationCard = ({ medication, onEdit, onDelete }: Props) => {
  const { t } = useTranslation()

  const dosageString = t('form.medication.card.dosage', {
    dose: medication.dosage.dose,
    times: medication.dosage.times,
    count: medication.dosage.dose,
  })

  const intervalString = t('form.medication.card.interval', {
    interval: medication.interval.interval,
    type: t(`form.medication.interval.${medication.interval.intervalType}`, {
      count: medication.interval.interval,
    }),
  })

  const weekdaysString = medication.interval.weekdays?.length > 0 ?
    t('form.medication.card.interval_weekdays', {
      days: medication.interval.weekdays.reduce((prev, cur, index) => {
        const dayString = t(cur)
        if (index === 0) {
          return dayString
        }
        if (index === medication.interval.weekdays.length - 1) {
          return `${prev} ${t('form.medication.card.interval_weekdays_join')} ${dayString}`
        }
        return `${prev}, ${dayString}`
      }, ''),
    }) : null

  let cardDescription = `${dosageString} ${intervalString}`
  if (weekdaysString) {
    cardDescription += ` ${weekdaysString}`
  }

  return (
    <div className='flex justify-between items-start px-6 py-4 border-b border-gray-100 rounded-md'>
      <div className="flex flex-col gap-2 md:gap-4">
        <p className="font-tag md:font-body2 font-semibold text-base-black">{medication.product?.productDescription}</p>
        <p className="font-tag md:font-body2 text-base-black opacity-60">
          {cardDescription}
        </p>
      </div>

      <div className="flex gap-2">
        <General.Edit02 className='w-6 h-6 p-0.5 radius-sm cursor-pointer hover:bg-neutral-100' onClick={onEdit} />
        <General.XClose className='w-6 h-6 p-0.5 radius-sm cursor-pointer hover:bg-neutral-100' onClick={onDelete} />
      </div>
    </div>
  )
}
